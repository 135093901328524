<template>
  <template
    v-if="
      !loading && themeSuccessfullyLoaded === false
        || !loading && themeSuccessfullyLoaded && !hasTheme
        || !loading && themeSuccessfullyLoaded && !hasValidation"
  >
    <the-error
      class="critical-error"
      message="Falha ao carregar dados críticos do sistema."
    />
  </template>

  <template v-else>
    <router-view />
  </template>

  <loader
    v-if="loading"
    :object="color.light"
    :color1="color.secondary"
    :color2="color.primary"
    :opacity="opacity"
    :bg="backgroundColor"
    size="5"
    speed="2"
    name="circular"
    objectbg="#999793"
  />
</template>

<script>
import { defineAsyncComponent } from 'vue'
import _ from 'lodash'

import config from '@/lib/data/config'
import themeFallback from '@/lib/data/theme.json'

import themeMixin from '@/mixins/themeMixin'
import loadingMixin from '@/mixins/loading'

const fallbackColorLoading = {
  primary: '#d3d3d3',
  secondary: '#e5e5e5',
  light: '#f8f8f8'
}

export default {
  name: 'App',
  components: {
    TheError: defineAsyncComponent(() => import('@/components/TheError'))
  },
  mixins: [themeMixin, loadingMixin],
  data: () => ({
    themeSuccessfullyLoaded: null
  }),
  computed: {
    hasTheme () {
      return !!this.theme.business
    },
    hasValidation () {
      return !!this.validationConfig && !!this.validationConfig.u_host_info
    },
    color () {
      return this.theme?.color || fallbackColorLoading
    },
    opacity () {
      return this.hasTheme ? 80 : 100
    },
    backgroundColor () {
      return this.hasTheme ? '#343a40' : '#fff'
    },
    businessName () {
      return this.theme?.business?.name || themeFallback.name
    },
    validationConfig () {
      if (this.isDomainToChange(window.location.hostname)) {
        return {
          u_host_info: process.env.VUE_APP_HOST_INFO,
          u_host_key: process.env.VUE_APP_HOST_KEY,
          u_hostname: process.env.VUE_APP_HOSTNAME,
          u_project_id: process.env.VUE_APP_PROJECT_ID,
          u_project_number: process.env.VUE_APP_PROJECT_NUMBER,
          u_mobile_sdk_app_id: process.env.VUE_APP_MOBILE_SDK_APP_ID
        }
      }

      return this.theme.validation
    }
  },
  async mounted () {
    this.loading = true

    await this.loadTheme()

    this.loading = false
  },
  methods: {
    async loadTheme () {
      const version = sessionStorage.getItem(this.themeVersionKey)
      let theme = sessionStorage.getItem(this.themeKey)

      // check version
      const isSameVersion = version && _.isEqual(version, config.THEME_VERSION)

      try {
        // if already loaded in $store
        if (isSameVersion && this.theme.business) return

        this.loading = true

        // get valid theme
        theme = !isSameVersion || !theme
          ? await this.fetchTheme()
          : JSON.parse(theme)

        // set theme
        await this.setTheme({ theme })
        this.registerCSSVariables()

        // to save the formatted theme (this.theme)
        sessionStorage.setItem(this.themeVersionKey, config.THEME_VERSION)
        sessionStorage.setItem(this.themeKey, JSON.stringify(this.theme))
      } catch (error) {
        this.themeSuccessfullyLoaded = false
      } finally {
        this.themeSuccessfullyLoaded = true
        this.loading = false
      }
    }
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;
}

body {
  overflow: hidden;
}

section {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>

<style lang="scss" scoped>
.critical-error {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;

  max-width: 30vw;
  min-width: 280px;
  background-color: $color-light;

  transform: translate(-50%, -50%);
}
</style>
