import axios from 'axios'

import Base from './Base'

export default class INSS extends Base {
  constructor () {
    super()

    delete axios.defaults.headers.common.Authorization // prevent CORS errors
    delete axios.defaults.headers.common['X-Authorization'] // prevent CORS errors

    this.api = axios
  }

  sendAuthorizationTerm (params) {
    return this.api.post('/inss/authorization_term', { ...params })
  }
}
