import { createApp } from 'vue'
import loader from 'vue-ui-preloader'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'
import money from 'v-money3'

import './registerServiceWorker'
import App from './App.vue'
import router from './router'
import store from './store'

import { Field, Form } from './plugins/vee-validate'

import '@fortawesome/fontawesome-free/css/all.css'

require('@/assets/scss/main.scss')
require('@/assets/scss/material-form.scss')

const app = createApp(App)

// debug
if (process.env.VUE_APP_ENV === 'staging') app.config.devtools = true

// use
app.use(store)
app.use(router)
app.use(loader)
app.use(money)

// components
app.component('VValidateObserver', Form)
app.component('VValidate', Field)

// sentry
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://ada29657a6084892ba84fdf07d53a154@o1313975.ingest.sentry.io/6564842',
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', /^\//]
      })
    ],
    tracesSampleRate: 0.1
  })
}

// init
app.mount('#app')
