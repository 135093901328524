<template>
  <main>
    <img class="validation__logo" :src="logo" :alt="businessName" />
    <i class="fas fa-check-circle fa-10x"></i>
    <div>
      <h2>Obrigado!</h2>

      <p>Com a sua autorização vamos consultar a sua margem para saque.</p>
    </div>
  </main>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'INSSSuccessfullySent',
  computed: {
    ...mapState({
      theme: (state) => state.theme.theme
    }),
    logo () {
      return `${this.theme.logo?.desktop}` || ''
    },
    businessName () {
      return this.theme.business?.name || ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';

main {
  @include base();
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 90vh;
  h2 {
    color: #1a1e39;
  }

  p {
    color: #4f5051;
  };

  i {
    color: #00e4b4;
  }

  .validation__logo {
    max-width: 70%;
    text-align: center;
    margin: 0 auto;
  }
}
</style>
