import Base from './Base'

// const ALT_API_URL = process.env.VUE_APP_ALT_BASE_API_URL + '/anti-fraud'
const ALT_API_URL = 'https://ymlzcthbmqcpwaiwcg3hikmbpa0rsehz.lambda-url.us-east-1.on.aws/v1/anti-fraud'

export default class Validation extends Base {
  constructor () {
    super()
    this.baseURL = '/anti-fraud'
  }

  async login (data) {
    return this.api
      .post(`${this.baseURL}/proposal-verification`, data)
      .then(({ data }) => data)
  }

  async loginCorban (data) {
    return this.api
      .post(`${this.baseURL}/corban/login`, data)
      .then(({ data }) => data)
  }

  async postDocs (data) {
    return this.api
      .post(`${ALT_API_URL}/document-verification`, data)
      .then(({ data }) => data)
  }

  async postCorbanDocs (data) {
    return this.api
      .post(`${ALT_API_URL}/corban/document-verification`, data)
      .then(({ data }) => data)
  }

  async postSelfie (data) {
    return this.api
      .post(`${ALT_API_URL}/selfie`, data)
      .then(({ data }) => data)
  }

  async postCorbanSelfie (data) {
    return this.api
      .post(`${ALT_API_URL}/corban/selfie`, data)
      .then(({ data }) => data)
  }

  async check (data) {
    return this.api.post(`${this.baseURL}/check`, data)
  }

  async checkCorban (data) {
    return this.api.post(`${this.baseURL}/corban/check`, data)
  }

  async checkScore (params) {
    return this.api.post(`${this.baseURL}/check-score`, params)
  }

  async getVideoCall (videoCallId) {
    return this.api.get(`${this.baseURL}/video-calls/${videoCallId}`)
  }
}
