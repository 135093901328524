import config from '../../config'

import Documents from '@/views/Documents.vue'

export default [
  {
    name: `${config.BASIC}`,
    path: `${config.PATH}/:token/dados-pessoais`,
    component: Documents
  }
]
