<template>
  <div
    :class="{ loading: config.loading }"
    class="validation-documents is--full"
  >
    <base-header :current="2">Confirmar dados</base-header>

    <div class="validation-documents__container slide-in">
      <div v-if="message.error" class="alert">
        <the-error :message="message.text" />

        <button class="button btn mt-5" @click.prevent="reload">
          <span>Recarregar</span>
          <span class="icon">
            <i class="fas fa-undo" />
          </span>
        </button>
      </div>
      <template v-else>
        <div class="validation-documents__content">
          <template v-if="config.loading">
            <span class="icon spinner">
              <i class="fas fa-spinner"></i>
            </span>
            <span>carregando ...</span>
          </template>

          <template v-else>
            <template v-if="!info.tac && !info.insurance_premium">
              <the-error v-if="!tcEligible" :message="insuranceMessage" type="info">
                <span
                  class="ml-1 is-clickable is-underlined"
                  @click="modal.insurance = true"
                >Entenda</span>
              </the-error>
            </template>

            <template
              v-for="({ label, value, signal, suffix }, index) in simulation"
              :key="index"
            >
              <div  class="control">
                <label>{{ label }}</label>
                <span v-if="signal" class="input">
                  {{ money(value) }}
                </span>
                <span v-else class="input">{{ value }} {{ suffix }}</span>
                <i class="bar" />
              </div>
            </template>

            <ul class="list mt-5">
              <li class="control">
                <label>Parcelas</label>
              </li>
              <li
                v-for="({ due_date, total_amount }, index) in periods"
                :key="index"
                class="list__item text"
              >
              <span v-if="isProductCreditCard" key="date-card">
                  {{ due_date.split('-').reverse().join('/').slice(3) }}:
                </span>
                <span v-else key="date-fgts">
                  {{ truncate(due_date, 4, '') }}:
                </span>
                <input :value="money(total_amount)" type="text" disabled />
              </li>
              <li class="control">
                <i class="bar" />
              </li>
            </ul>
          </template>
        </div>

        <div v-if="!config.loading" class="validation-documents__ctrl">
          <button class="button btn" @click="handleTerms(true)">
            <span>Aceito</span>
            <span class="icon">
              <i class="fas fa-check" />
            </span>
          </button>
          <button class="button btn is--secondary" @click="handleTerms(false)">
            <span>Não aceito</span>
            <span class="icon">
              <i class="fas fa-times" />
            </span>
          </button>
        </div>
      </template>
    </div>
  </div>

  <the-modal
    :is-active="modal.insurance"
    @close="modal.insurance = false"
  >
    <template #title>Entenda</template>
    <template #content>
      <div class="content">
        <p>
          Seguradora: Mapfre, vide dados previstos na CCB - cédula de crédito bancário.
        </p>
        <p>
          Objeto: Seguro em favor dos Herdeiros legais do Segurado (tomador do crédito), a quem deverá ser paga a indenização, no valor a que tem direito em decorrência da obrigação a que o seguro está atrelado, apurada na data da ocorrência do evento coberto e limitada ao capital segurado contratado.
        </p>
        <p>
          Se ocorrer a morte do segurado:
        </p>
        <p>
          Esta cobertura tem por objetivo garantir, mediante o pagamento de Prêmio, o pagamento do Capital Segurado contratado ao(s) Beneficiário(s) (sucessores legais previstos em Lei), em caso de morte natural ou acidental do Segurado, decorrente exclusivamente de causas naturais e/ou Acidente Pessoal coberto, ocorridas durante a Vigência do Seguro, observados os Riscos expressamente Excluídos e as demais disposições contratuais.
        </p>
        <p>
          Se ocorrer invalidez permanente total por acidente - IPTA:
        </p>
        <p>
          Esta cobertura tem por objetivo garantir, mediante pagamento de Prêmio, o pagamento do Capital Segurado contratado ao(s) Beneficiário(s), caso o Segurado venha a se tornar total e permanentemente inválido, pela perda ou Impotência Funcional definitiva e total de membro ou órgão em decorrência de lesão física causada exclusivamente por Acidente Pessoal coberto, conforme especificado na cláusula DEFINIÇÕES das Condições Gerais, ocorrido durante a vigência do seguro, observados os riscos excluídos e as demais disposições contratuais.
        </p>
      </div>
    </template>
  </the-modal>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import _ from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Sentry from '@sentry/vue'

import QITechAPI from '@/lib/api/QITech'
import Card from '@/lib/api/Card'

import navigatorCompatibility from '@/mixins/navigatorCompatibility'

import { truncate } from '@/utils/filters'

export default {
  name: 'ValidationConsult',
  components: {
    BaseHeader: defineAsyncComponent(() => import('@/components/BaseHeader')),
    TheError: defineAsyncComponent(() => import('@/components/TheError')),
    TheModal: defineAsyncComponent(() => import('@/components/TheModal'))
  },
  mixins: [navigatorCompatibility],
  data: () => ({
    info: {},
    form: {
      terms: false
    },
    message: {
      error: false,
      text: ''
    },
    config: {
      loading: false
    },
    insuranceMessage: 'Trata-se de um cliente assíduo, por isso, é importante garantir a segurança da operação para a hipótese de um sinistro. A operação contará com Seguro e está isenta de TC.',
    modal: {
      insurance: false
    }
  }),
  computed: {
    ...mapState({
      account: (state) => state.validation.account
    }),
    ...mapGetters({
      isProductCreditCard: 'validation/isProductCreditCard',
      isProductINSS: 'validation/isProductCreditCard'
    }),
    tcEligible () {
      if (this.info.tc_eligible === null) return true
      return this.info.tc_eligible
    },
    simulation () {
      const keys = Object.keys(this.info)
      let productType = 'Empréstimo saque-aniversário FGTS'

      if (keys.length === 0) return []

      if (this.isProductCreditCard) productType = 'Empréstimo Cartão de Crédito'

      if (this.isProductINSS) productType = 'Empréstimo INSS'

      return [
        {
          label: 'Tipo de produto',
          value: productType
        },
        {
          label: 'Valor a receber',
          value: this.info.disbursed_issue_amount,
          signal: 'money'
        },
        {
          label: 'Taxa de Juros',
          value: (this.info.monthly_rate * 100).toFixed(2),
          suffix: '% a.m.'
        },
        {
          label: 'Custo efetivo total',
          value: (this.info.cet * 100).toFixed(2),
          suffix: '% a.m.'
        },
        {
          label: this.tcEligible ? 'TC' : 'Seguro',
          value: this.tcEligible
            ? this.info.tac
            : this.info.insurance_amount,
          signal: 'money'
        }
      ]
    },
    periods () {
      const keys = Object.keys(this.info)

      if (keys.length === 0) return []

      if (!this.info.periods) return []

      return this.info.periods.map(({ due_date, total_amount, amount }) => ({
        due_date,
        total_amount: total_amount?.toFixed(2) || amount?.toFixed(2)
      }))
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async (vm) => await vm.routeProtection({ vm, route: { to, from } }))
  },
  async mounted () {
    if (this.isProductCreditCard) this.creditCardSimulation()
    else this.consult()
  },
  methods: {
    ...mapActions({
      setAccount: 'validation/SET_ACCOUNT'
    }),

    // handlers
    setData ({ data }) {
      for (const key of Object.keys(this.info)) {
        delete this.info[key]
      }
      for (const key of Object.keys(data)) {
        this.info[key] = data[key]
      }
    },
    truncate (...args) {
      return truncate(...args)
    },
    money (value) {
      return parseFloat(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    },
    formatDate (date) {
      return date.split('-').reverse().join('/')
    },
    handleError (err) {
      this.message.text = _.isString(err)
        ? err
        : err?.error?.response?.data?.error?.message ||
          err?.response?.data?.error?.message ||
          err?.error?.message ||
          err?.message ||
          'Ocorreu um problema durante sua solicitação'

      if (process.env.VUE_APP_ENV === 'production') {
        Sentry.captureMessage(new Error('consult'), {
          tags: {
            ...this.account
          }
        })
      }

      this.message.error = true
      this.config.loading = false
    },

    // api
    async consult () {
      const api = new QITechAPI()
      const { account_id } = this.account

      this.config.loading = true
      this.message.error = false

      try {
        const { data } = await api.confirm({ account_id })

        if (data.data.permiteConsulta === false) {
          this.handleError({
            error:
              'Não foi possível realizar a consulta, por favor, tente novamente mais tarde.'
          })
        } else this.setData(data)
      } catch (err) {
        this.handleError(err || { error: err })
      } finally {
        this.config.loading = false
      }
    },
    async creditCardSimulation () {
      const api = new Card()
      const params = {
        account_id: this.account.account_id,
        document: (this.account.document || '').replace(/\D/g, '')
      }

      this.config.loading = true

      api
        .creditCardSimulation(params)
        .then((data) => this.setData(data.data))
        .catch((err) => this.handleError(err || { error: err }))
        .finally(() => {
          this.config.loading = false
        })
    },

    // navigation
    reload () {
      window.location.reload(true)
    },
    async handleTerms (state) {
      this.form.terms = state

      this.account = {
        ...this.account,
        terms: state
      }

      this.nextStep()
    },
    async preauthorization () {
      const api = new Card()

      this.config.loading = true

      await api
        .preauthorization({ account_id: this.account.account_id })
        .then(() => this.$router.push({ name: `${this.routesNames.SELFIE}` }))
        .catch((err) => this.handleError(err || { error: err }))
        .finally(() => {
          this.config.loading = false
        })
    },
    async nextStep () {
      const has_warranty = this.account?.has_warranty ?? false
      const already_verified = this.account?.card_verified ?? false

      let name = `${this.routesNames.SELFIE}`

      if (this.isProductCreditCard) {
        if (!has_warranty && !already_verified) name = `${this.routesNames.CRED_CARD}`

        if (has_warranty && !already_verified) name = `${this.routesNames.CRED_CARD_VALIDATION}`

        if (has_warranty && already_verified) return this.preauthorization()
      }

      this.$router.push({ name })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';
@import '@/assets/scss/src/_btn.scss';

.validation-documents {
  @include base();

  [class*='__ctrl'] {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 30px;

    .is--secondary {
      background-color: $color-light;
      border-color: $color-light;
    }
  }
}

.alert {
  margin-top: 1rem;
}

div.control {
  margin-bottom: 0;

  display: grid;
  width: 100%;

  grid-template-columns: 1fr;

  &:first-child {
    margin-top: 0;
  }

  + div.control {
    margin-top: 30px;
  }

  label {
    color: #616161;
  }

  span {
    display: block;
    border: none;
    height: auto;
    box-shadow: none;
    padding: 0.5em 0.125rem 0.75em;
    background-color: rgba(#000, 0);

    color: $color-secondary;
    line-height: 1em;
  }

  input {
    color: $color-secondary;
  }
}

.spinner {
  position: absolute;
  top: 20px;
  right: 20px;

  font-size: 2em;
  color: $color-primary;

  animation: 0.75s linear infinite spinner-border;
}

.list {
  margin: 0;
  padding: 0;

  &__item {
    $padding: 30px;

    position: relative;
    margin-bottom: 20px;

    display: flex;
    padding-left: $padding;

    &::before {
      $size: 10px;

      content: '';

      position: absolute;
      top: 50%;
      left: (($padding / 2) - ($size / 2));

      display: block;
      width: $size;
      height: $size;
      background-color: $color-primary;

      transform: translateY(-50%);
    }

    input,
    span {
      display: inline-block;

      font: {
        family: inherit;
        size: inherit;
      }
    }

    span {
      color: $color-secondary;
    }

    input {
      margin-left: 5px;

      border: none;
      background-color: rgba(#fff, 0);

      color: #444;
    }
  }
}

.content {
  p {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}
</style>
