<template>
  <main v-if="isValidDeviceEnv" class="validation-login">
    <base-header :has-steps="false" :has-title="false" />

    <v-validate-observer
      v-slot="{ handleSubmit }"
      class="validation-login__container fade-in"
    >
      <form
        class="validation-login__form"
        @submit.prevent="handleSubmit(submit)"
      >
        <h2 class="m-0">Termo de validação</h2>

        <!-- form -->
        <v-validate
          v-slot="{ errors, field }"
          rules="required|CPF|length:14"
          name="CPF"
        >
          <base-input
            v-bind="field"
            :model-value="form.document"
            :has-error="!!errors[0]"
            :first-error="errors[0]"
            type="tel"
            maxlength="14"
            format="###.###.###-##"
            @input="form.document = field.value"
          >
            <span>CPF</span>
            <span class="required">*</span>
          </base-input>
        </v-validate>

        <v-validate
          v-slot="{ errors, field }"
          rules="required"
          name="Nome"
        >
          <base-input
            v-bind="field"
            :model-value="form.name"
            :has-error="!!errors[0]"
            :first-error="errors[0]"
            @input="form.name = field.value"
          >
            <span>Nome</span>
            <span class="required">*</span>
          </base-input>
        </v-validate>

        <v-validate
          v-slot="{ errors, field }"
          rules="required"
          name="E-mail"
        >
          <base-input
            v-bind="field"
            :model-value="form.email"
            :has-error="!!errors[0]"
            :first-error="errors[0]"
            @input="form.email = field.value"
          >
            <span>E-mail</span>
            <span class="required">*</span>
          </base-input>
        </v-validate>

        <v-validate
          v-slot="{ errors, field }"
          rules="required"
          name="Número de telefone"
        >
          <base-input
            v-bind="field"
            :model-value="form.number"
            :has-error="!!errors[0]"
            :first-error="errors[0]"
            type="tel"
            format="(##) #####-####"
            @input="form.number = field.value"
          >
            <span>Número de telefone</span>
            <span class="required">*</span>
          </base-input>
        </v-validate>

        <!-- term -->
        <label class="checkbox">
          <input
            v-model="accepted"
            type="checkbox"
          >
            Eu aceito os
          <span class="is-featured" @click.prevent="showTerms = !showTerms">
            termos de validação.
          </span>
        </label>

        <transition>
          <aside
            v-if="showTerms"
            class="validation-term"
          >
            <blockquote>Eu, <strong>{{ form.name?.toUpperCase() || 'PESSOA' }}</strong>, portador do CPF nº <strong>{{ form.document || '###.###.###-##' }}</strong>,
              a fim permitir a consulta de margem e simulação para empréstimo consignado e, viabilizar o prosseguimento da proposta do banco credor (instituição
              consignatária) QI Sociedade de Crédito, autorizo o INSS e Dataprev a disponibilizar as informações disponíveis do meu beneficio.
            </blockquote>
          </aside>
        </transition>

        <button
          class="button btn is-primary"
          type="submit"
          :disabled="validateSubmit"
        >
          Enviar
        </button>
      </form>
    </v-validate-observer>
  </main>

  <div v-else class="validation-login">
    <base-header :has-steps="false" :has-title="false" />

    <div  class="validation-login__container fade-in">
      <div class="validation-login__content">
        <TheInvalid  />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import UAParser from 'ua-parser-js'
import { toast } from 'bulma-toast'

import LoadingMixin from '@/mixins/loading'
import navigatorCompatibility from '@/mixins/navigatorCompatibility'

import BaseInput from '@/components/BaseInput.vue'

import INSSApi from '@/lib/api/INSS.js'

export default {
  name: 'INSSForm',
  components: {
    BaseInput,
    BaseHeader: defineAsyncComponent(() => import('@/components/BaseHeader')),
    TheInvalid: defineAsyncComponent(() => import('@/components/TheInvalid'))
  },
  mixins: [navigatorCompatibility, LoadingMixin],
  data () {
    return {
      form: {
        document: '',
        name: '',
        email: '',
        number: ''
      },
      device: {
        ip_address: '',
        model: '',
        lat: '',
        long: ''
      },
      accepted: false,
      showTerms: false
    }
  },
  computed: {
    formNotEmpty () {
      return Object.values(this.form).every(elem => elem !== null && elem !== '')
    },
    area_code () {
      return this.form.number?.substring(1, 3)
    },
    validateSubmit () {
      return [this.accepted && this.formNotEmpty].some((valid) => valid === false)
    }
  },
  watch: {
    form: {
      handler () {
        this.accepted = false
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    back () {
      this.$router.push('/')
    },
    getLocation () {
      return this.getCoordinates()
        .then(({ coords }) => {
          return coords
        })
        .catch((error) => {
          if (error.code === error.PERMISSION_DENIED) {
            toast({
              message: 'Por favor, habilite a geolocalização para que possamos prosseguir',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true
            })
          }
        })
    },
    getCoordinates () {
      return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
    },
    getDeviceData () {
      const parser = new UAParser()
      return parser.getResult()
    },
    getUserIP () {
      return fetch('https://checkip.amazonaws.com/')
        .then((res) => res.text())
        .catch(({ response }) => {
          const { errors } = response.data.error
          const message = Object.values(errors)[0][0]

          toast({
            message: message,
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true
          })
        })
    },
    async mountDeviceData () {
      const location = await this.getLocation()

      if (location) {
        const { latitude, longitude } = location
        const { ua: model } = await this.getDeviceData()
        this.device.ip_address = await this.getUserIP()
        this.device.model = model
        this.device.lat = `${latitude}`
        this.device.long = `${longitude}`
      }
    },
    async submit () {
      const api = new INSSApi()
      this.loading = true

      await this.mountDeviceData()

      const params = {
        ...this.form,
        ...this.device,
        document: this.form.document.replace(/\D/g, ''),
        number: this.form.number.split(')')[1].replace(/\D/g, ''),
        area_code: this.area_code
      }

      api.sendAuthorizationTerm(params)
        .then(() => {
          this.$router.push({ name: 'form-successfully-sent' })
        })
        .catch(({ request }) => {
          let { response } = request
          let message = ''

          if (typeof response === 'string') {
            response = JSON.parse(response)
            message = response.error.message
          } else {
            const { errors } = request.response.data.error
            message = Object.values(errors)[0][0]
          }

          toast({
            message: message,
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/src/_validation.scss';
@import '@/assets/scss/src/_btn.scss';

$recuo: 30px;

.v-enter-active {
  transition: all 1s;
}

.v-enter-from {
  opacity: 0;
  transform: translate(0, 50px)
}

.validation-login {
  @include base();

  @import '@/assets/scss/src/_login.scss';

  &__form {
    row-gap: $recuo * .75;
  }
}

main {
  h2 {
    text-align: center;
    font-size: 1.2rem;
  }

  .validation-term {
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: #f9f9f9;
  }

  .is-featured {
    color: hsl(171, 100%, 41%);

    :hover {
      cursor: pointer;
    }
  }

  button {
    min-width: 100%;
  }
}
</style>
