const hasProductID = ({ account }) => {
  return Object.hasOwnProperty.call(account, 'product_id')
}

export const getters = {
  getSidesOptions (state) {
    const entries = Object.entries(state.sides)
    const data = {}

    for (const [key, value] of entries) {
      data[value] = key
    }

    return data
  },

  isCorban (state) {
    return !hasProductID(state)
  },

  isProductCreditCard (state) {
    if (!hasProductID(state)) return false
    return state.account.product_id === 8
  },

  isProductFGTS (state) {
    if (!hasProductID(state)) return false
    return state.account.product_id === 3
  },

  isProductINSS (state) {
    if (!hasProductID(state)) return false
    return state.account.product_id === 12
  }
}
