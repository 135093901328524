import axios from 'axios'

import config from '@/lib/data/config'

export default class API {
  constructor () {
    if (!config.BASE_API_URL) throw new Error('Base URL not found')

    axios.defaults.baseURL = config.BASE_API_URL
    axios.defaults.headers.common['X-Authorization'] = `${config.API_KEY}`
    axios.defaults.headers.common['content-type'] = 'application/json'

    this.api = axios
  }

  async getTheme ({ domain }) {
    delete axios.defaults.headers.common.Authorization // prevent CORS errors
    delete axios.defaults.headers.common['X-Authorization'] // prevent CORS errors

    return this.api.get(`/users/tenants/${domain}`, {
      headers: {
        'X-Authorization': config.API_KEY,
        'content-type': 'application/json'
      }
    })
  }
}
