<template>
  <div :class="{ loading: config.loading }" class="card-verification">
    <base-header :current="3">
      Verificação do Cartão de Crédito
    </base-header>

    <div class="card-verification__container slide-in">
      <template v-if="message.show">
        <the-error :message="message.text" class="alert" />
      </template>

      <template v-if="!isSucceeded">
        <div class="card-verification__content">
          <i
            :class="[config.expanded ? 'fa-times-circle' : 'fa-question-circle']"
            class="fas"
            @click="config.expanded = !config.expanded"
          />
          <p>
            Utilize o campo abaixo para informar o valor de pré autorização lançado agora no seu cartão de crédito.
          </p>
          <p v-if="config.expanded" class="expanded">
            Esta é uma etapa de validação da titularidade do cartão de crédito utilizado por você.
          </p>
        </div>

        <v-validate-observer v-slot="{ handleSubmit }">
          <form
            class="card-verification__form"
            @submit.prevent="handleSubmit(verify)"
          >
            <v-validate v-slot="{ errors, field }"
              v-model="form.value"
              rules="required|alert_min:0.01"
              name="Valor"
            >
              <div
                :class="{'has-error': !!errors[0] }"
                class="control has-icons has-icons-right"
              >
                <money3
                  v-model.lazy="form.value"
                  v-bind="config.money"
                  type="tel"
                />
                <input v-bind="field" type="hidden">
                <label class="label">
                  <span>Valor (R$)</span>
                  <span class="required">*</span>
                </label>
                <i class="bar" />
                <p v-if="errors[0]" class="help is-error">
                  {{ errors[0] }}
                </p>
              </div>
            </v-validate>

            <div>
              <button :disabled="config.loading" class="button btn" type="submit">
                <span>Verificar</span>
              </button>
            </div>
          </form>
        </v-validate-observer>
      </template>

      <template v-else>
        <div class="card-verification__content">
          <p>
            <strong>Cartão verificado com sucesso!</strong>
          </p>
          <p>Confirmamos a disponibilidade de crédito em seu cartão!</p>
        </div>
        <div v-if="!message.show" class="validation-documents__ctrl">
          <button :disabled="config.loading" class="button btn" @click="handleNext">
            <span>Continuar</span>
            <span class="icon">
              <i class="fas fa-arrow-right" />
            </span>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'
import _ from 'lodash'
import * as Sentry from '@sentry/vue'

import Card from '@/lib/api/Card'

import navigatorCompatibility from '@/mixins/navigatorCompatibility'

export default {
  name: 'CardVerification',
  components: {
    BaseHeader: defineAsyncComponent(() => import('@/components/BaseHeader')),
    TheError: defineAsyncComponent(() => import('@/components/TheError'))
  },
  mixins: [navigatorCompatibility],
  data: () => ({
    form: {
      value: ''
    },
    message: {
      show: false,
      text: '',
      type: 'error'
    },
    config: {
      loading: false,
      expanded: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false, /* doesn't work with directive */
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        debug: false
      }
    },
    isSucceeded: false
  }),
  computed: {
    ...mapState({
      account: (state) => state.validation.account
    })
  },
  beforeRouteEnter (to, from, next) {
    next(async (vm) => await vm.routeProtection({ vm, route: { to, from } }))
  },
  methods: {
    handleError (err) {
      this.message.text = _.isString(err)
        ? err
        : err?.error?.response?.data?.error?.message ||
          err?.response?.data?.error?.message ||
          err?.error?.message ||
          err?.message ||
          'Ocorreu um problema durante sua solicitação'

      if (process.env.VUE_APP_ENV === 'production') {
        Sentry.captureMessage(new Error('consult'), {
          tags: {
            ...this.account
          }
        })
      }

      this.message.show = true
      this.config.loading = false
    },
    async verify () {
      const api = new Card()
      const { proposal_id, account_id } = this.account

      const params = {
        validation_value: parseFloat(this.form.value),
        account_id,
        proposal_id
      }

      this.config.loading = true
      this.message.show = false

      await api
        .verification(params)
        .then(() => {
          this.isSucceeded = true
        })
        .catch((err) => this.handleError(err || { error: err }))
        .finally(() => {
          this.config.loading = false
        })
    },
    async handleNext () {
      const api = new Card()
      const { proposal_id, account_id } = this.account

      this.config.loading = true

      try {
        await api.preauthorization({
          account_id,
          proposal_id
        })

        this.config.loading = false

        this.nextStep()
      } catch (err) {
        this.handleError(err || { error: err })
      }
    },
    nextStep () {
      this.$router.push({ name: `${this.routesNames.SELFIE}` })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';
@import '@/assets/scss/src/_btn.scss';
@import '@/assets/scss/src/_loading-skeleton.scss';

.card-verification {
  @include base();

  &__container {
    display: grid;
    row-gap: $recuo;

    &.fade-in {
      animation: fadeIn 1s linear;
    }
  }

  &__content {
    position: relative;

    color: $color-secondary;
    text-align: center;

    transition: all 0.35s ease;

    .expanded {
      margin-top: 24px;

      padding: 20px;
      border-radius: 5px;
      background-color: $color-light;
    }

    .fas[class*='circle'] {
      position: absolute;
      top: #{($recuo - 10) * (-1)};
      right: #{($recuo - 10) * (-1)};

      border-radius: 50%;
      overflow: hidden;

      font-size: 1.25em;

      cursor: pointer;
    }

    .alert {
      margin-top: 1rem;
    }

    .button {
      margin-top: $recuo;
    }
  }

  &__form {
    display: grid;

    grid-template-columns: 1fr;
    row-gap: $recuo + 10;

    .required {
      margin-left: 5px;
      color: $color-primary;
    }

    .button.is-text {
      margin-top: $recuo / 2;
    }

    .has-error {
      $mf-error-color: #ff5100;

      .legend.legend,
      &.control .label.label,
      p.is-error {
        color: $mf-error-color;
      }
      &.control,
      &.checkbox-control,
      &.radio-control,
      &.radio-control {
        .form-help,
        .helper {
          color: $mf-error-color;
        }
      }
      .bar {
        &::before {
          background: $mf-error-color;
          left: 0;
          width: 100%;
        }
      }
    }

    &::v-deep {
      .control {
        margin-bottom: 0;
      }
    }
  }
}
</style>
