import config from '../../config'

import PhotoDocs from '@/views/PhotoDocs.vue'

const pathSuffix = 'foto-documento/:type/:side'

export default [
  {
    name: `${config.DOCS}`,
    path: `${config.PATH}/:token/${pathSuffix}`,
    component: PhotoDocs,
    props: (route) => {
      const { type, side } = route.params
      return { docType: type, docSide: side }
    }
  },
  {
    name: `${config.DOCS_CORBAN}`,
    path: `${config.ALIAS_PATH}/:token/${pathSuffix}`,
    component: PhotoDocs,
    props: (route) => {
      const { type, side } = route.params
      return { docType: type, docSide: side }
    }
  }
]
