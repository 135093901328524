import config from '../../config'

import PhotoSelfie from '@/views/PhotoSelfie.vue'

const pathSuffix = 'foto-documento/selfie'

export default [
  {
    name: `${config.SELFIE}`,
    path: `${config.PATH}/:token/${pathSuffix}`,
    component: PhotoSelfie
  },
  {
    name: `${config.SELFIE_CORBAN}`,
    path: `${config.ALIAS_PATH}/:token/${pathSuffix}`,
    component: PhotoSelfie
  }
]
