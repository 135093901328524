import INSSForm from '@/views/INSSForm.vue'
import INSSSuccessfullySent from '@/views/INSSSuccessfullySent.vue'

const modulePrefix = '/inss'

export default [
  {
    name: 'inss-form',
    path: `${modulePrefix}/form`,
    component: INSSForm,
    props: true
  },
  {
    name: 'form-successfully-sent',
    path: `${modulePrefix}/form-successfully-sent`,
    component: INSSSuccessfullySent,
    props: true
  }
]
