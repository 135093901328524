import config from '../../config'

import AddCreditCard from '@/views/AddCreditCard.vue'
import CardVerification from '@/views/CardVerification.vue'

export default [
  {
    name: `${config.CRED_CARD}`,
    path: `${config.PATH}/:token/cartao-credito`,
    component: AddCreditCard
  },
  {
    name: `${config.CRED_CARD_VALIDATION}`,
    path: `${config.PATH}/:token/cartao-verificacao`,
    component: CardVerification
  }
]
