const NAME = 'validation'
const ALIAS_NAME = 'corban'

const PATH = '/validacao'
const ALIAS_PATH = '/corban'

export default {
  /** names */
  NAME,
  ALIAS_NAME,

  LOGIN: `${NAME}-login`,
  LOGIN_CORBAN: `${ALIAS_NAME}-login`,

  BASIC: `${NAME}-documents`,

  CONSULT: `${NAME}-consult`,

  CRED_CARD: 'add-credit-card',
  CRED_CARD_VALIDATION: `${NAME}-card-verification`,

  SELFIE: `${NAME}-photo-selfie`,
  SELFIE_CORBAN: `${ALIAS_NAME}-photo-selfie`,

  TYPE: `${NAME}-photo-type`,
  TYPE_CORBAN: `${ALIAS_NAME}-photo-type`,

  DOCS: `${NAME}-photo-docs`,
  DOCS_CORBAN: `${ALIAS_NAME}-photo-docs`,

  FINAL: `${NAME}-final`,
  FINAL_CORBAN: `${ALIAS_NAME}-final`,

  /** paths */
  PATH,
  ALIAS_PATH
}
