<template>
  <div :class="{ loading }" class="video-call">
    <base-header
      :has-steps="false"
      :has-title="false"
      style="margin: 10px auto"
    />

    <section
      class="welcome-page"
      v-if="step == 1"
    >
      <h2>Olá!</h2>

      <div
        class="info"
        v-for="(info, index) in infoList"
        :key="index"
      >
        <span>{{ index + 1 }}</span>
        <p>{{ info }}</p>
      </div>

      <button
        class="button btn"
        type="submit"
        @click="step = 2"
      >
        Estou Pronto!
      </button>
    </section>
    <section v-show="step == 2">
      <div class="video-call__container fade-in">
        <div v-if="videoCallEmbedUrl" class="video-call__room">
          <iframe
            allow="camera; microphone; fullscreen; speaker; display-capture"
            :src="videoCallEmbedUrl">
          </iframe>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import themeMixin from '@/mixins/themeMixin'
import navigatorCompatibility from '@/mixins/navigatorCompatibility'

import ValidationAPI from '@/lib/api/Validation'

export default {
  name: 'VideoCall',
  mixins: [themeMixin, navigatorCompatibility],
  components: {
    BaseHeader: defineAsyncComponent(() => import('@/components/BaseHeader'))
  },
  data: () => ({
    message: {
      error: false,
      text: null
    },
    step: 1,
    loading: false,
    videoCallEmbedUrl: null,
    infoList: [
      'Certifique-se de que sua conexão de internet está estável e rápida o suficiente para vídeo e áudio contínuos.',
      'Habilite seu audio e câmera do seu telefone ou computador.',
      'Escolha um local apropriado onde você possa confirmar seus dados tranquilamente.',
      'Tenha seus documentos em mãos para eventual confirmação de dados.'
    ]
  }),
  computed: {
    videoCallId () {
      return this.$route.params.id
    },
    logo () {
      return this.theme.logo?.desktop || ''
    },
    businessName () {
      return this.theme.business?.name || ''
    }
  },
  async mounted () {
    await this.getVideoCall()
  },
  methods: {
    // handlers
    handleError ({ error }) {
      this.message.text =
        error?.response?.data?.error?.message ||
        error?.error?.response?.data?.error?.message ||
        error?.message ||
        error

      if (error?.errors) {
        for (const key in error?.errors) {
          if (Object.hasOwnProperty.call(error?.errors, key)) {
            const [message] = error?.errors[key]
            this.message.text += message
          }
        }
      }

      this.message.error = true
    },

    // api
    async getVideoCall () {
      const api = new ValidationAPI()

      this.loading = true
      this.message.error = false
      this.message.text = null

      await api
        .getVideoCall(this.videoCallId)
        .then(async (result) => {
          // [TODO]: data.data, why?
          this.videoCallEmbedUrl = result.data.data.client_embed_url
        })
        .catch(({ response }) =>
          this.handleError(response?.data || { error: response })
        )
        .finally(() => {
          this.loading = false
        })
    },

    // navigation
    back () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_variables.scss';
@import '@/assets/scss/src/_validation.scss';
@import '@/assets/scss/src/_loading-skeleton.scss';
.welcome-page {
  text-align: center;
  display: flex;
  gap: 16px;
  flex-direction: column;
  h2 {
    font-weight: bold;
    font-size: 1.5rem;
  }
  .info {
    span {
      &:before {
        content: "";
        background-color: $color-primary;
        width: 100%;
        height: 75%;
        position: absolute;
        z-index: -1;
        border-radius: 50%;
        left: 0;
      }
      position: relative;
      margin: 4px;
      padding: 8px;
      font-weight: bold;
      color: $color-secondary;
    }
    p {
      color: #4A4A4A;
      margin: 4px 0;
    }
  }
}
.video-call {
  &__container {
    display: grid;
    margin: 0 auto 5px;
    padding: 12px;
    row-gap: 10px;

    &.fade-in {
      box-shadown: none;
      animation: fadeIn 1s linear;
    }
  }

  &__room {
    position: relative;
    color: $color-secondary;
    text-align: center;
    transition: all 0.35s ease;
    iframe {
      height: calc(100vh - 92px);
      width: 100%;
    }
  }
}
</style>
