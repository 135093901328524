const BASE_API_URL = process.env.VUE_APP_BASE_API_URL
const THEME_VERSION = process.env.VUE_APP_THEME
const UNICO_PUBLIC_FOLDER_PATH = '/data'
const API_KEY = 'dUd4QCRb49kUoFqxd337BPorPphXllHxknY2crt1XckrxXkxkcakgj4fgnmOHSxz'

export default {
  BASE_API_URL,
  API_KEY,
  THEME_VERSION,
  UNICO_PUBLIC_FOLDER_PATH
}
