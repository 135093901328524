import config from '../../config'

import Final from '@/views/Final.vue'

const pathSuffix = 'sucesso'

export default [
  {
    name: `${config.FINAL}`,
    path: `${config.PATH}/:token/${pathSuffix}`,
    component: Final,
    props: true
  },
  {
    name: `${config.FINAL_CORBAN}`,
    path: `${config.ALIAS_PATH}/:token/${pathSuffix}`,
    component: Final,
    props: true
  }
]
