import axios from 'axios'

import Base from './Base'

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiT2N0YSJ9.8EJy7xvSqyeHhpSiR1Zh_SRnOxvUUdqEcgj-SH4pTGE'

export default class QITech extends Base {
  constructor () {
    super()

    delete axios.defaults.headers.common.Authorization // prevent CORS errors
    delete axios.defaults.headers.common['X-Authorization'] // prevent CORS errors

    axios.defaults.headers.common = { Authorization: `Bearer ${token}` }

    this.api = axios
  }

  confirm (params) {
    return this.api.get('/antecipate-fgts/simulation', { params })
  }
}
