<template>
  <div
    :class="{ hasAllPermissions: !message.error && hasAllPermissions }"
    class="validation-photo is--full"
  >
    <base-header
      v-show="showTitle"
      ref="header"
      class="validation-photo__header"
      :current="isCorban ? 2 : 6"
      :steps="isCorban ? 3 : 7"
    >
      Foto do documento
      <span class="type">{{ docType }}</span>
      <span class="side">{{ docSideLabel }}</span>
    </base-header>

    <div v-if="message.error" class="alert">
      <the-error :message="message.text" />
    </div>

    <div ref="container" class="validation-photo__container slide-in">
      <div
        ref="content"
        :class="{ expanded: config.expanded, hasPhotoLoaded }"
        class="validation-photo__content"
      >
        <i
          v-if="!message.error"
          :class="[config.expanded ? 'fa-times' : 'fa-question']"
          class="fas"
          @click="config.expanded = !config.expanded"
        />

        <div v-show="!config.expanded" key="image" class="photo">
          <span v-show="!hasPhotoLoaded && !config.loading">
            sem foto carregada
          </span>

          <span v-if="config.loading" class="icon spinner">
            <i class="fas fa-circle-notch" />
          </span>

          <div v-show="hasPhotoLoaded" ref="img" class="camera camera--img">
            <img
              :src="form.img"
              :alt="`${docType}-${docSideLabel}`"
              @load="handleImageLoaded"
            />
          </div>

          <div
            id="box-camera"
            ref="canvas"
            :class="{ hasPhotoLoaded }"
            class="camera--canvas"
          />

          <p v-show="config.showInfo" class="info">
            <span>type: {{ docType }}</span>
            <span>mode: {{ facingMode }}</span>
            <span>terms: {{ account.terms }}</span>
            <span>isVerse: {{ isVerse }}</span>
            <span>isOpened: {{ isOpened }}</span>
          </p>
        </div>

        <div
          v-show="showTutorial"
          key="tutorial"
          class="validation-photo__tutorial"
        >
          <template v-if="message.error">
            <p>
              <strong>Ocorreu um erro na solicitação de recursos</strong>
            </p>
            <ul class="list error">
              <li>É preciso garantir permissão de acesso à câmera</li>
              <li>
                Feche outras aplicações que possam estar utilizando a câmera
              </li>
              <li>
                Dispositivos podem, momentaneamente, falhar em liberar o acesso
                à câmera. Tente novamente em instantes
              </li>
            </ul>
          </template>
          <template v-else>
            <p>
              <strong>
                Cuidados para tirar a foto do seu documento corretamente
              </strong>
            </p>
            <ul class="list">
              <template v-if="!hasAllPermissions">
                <li>Permitir acesso: câmera</li>
              </template>
              <li>Tire seu documento do plástico protetor</li>
              <li>Verifique se a imagem está nítida e sem reflexo</li>
              <li>Imagem precisa estar bem enquadrada</li>
              <li>É recomendado que na imagem não haja espaços sobrando</li>
            </ul>
          </template>
        </div>
      </div>

      <div class="validation-photo__ctrl">
        <button
          v-if="message.error"
          key="btn-go-home"
          class="button btn"
          @click="retry"
        >
          <span>Tentar novamente</span>
          <span class="icon">
            <i class="fas fa-undo" />
          </span>
        </button>

        <button
          v-else-if="!hasAllPermissions"
          key="btn-allow"
          class="button btn"
          @click="loadSDK"
        >
          <span>Permitir</span>
          <span class="icon">
            <i class="fas fa-shield-alt" />
          </span>
        </button>

        <template v-else-if="!config.expanded">
          <span v-if="hasPhotoLoaded">
            <button
              class="button btn is-secondary"
              @click="resetImage({ toPlay: true })"
            >
              <i class="fas fa-undo" />
            </button>

            <button class="button btn" @click="submit">
              <span>Salvar</span>
              <span class="icon">
                <i class="fas fa-save" />
              </span>
            </button>
          </span>
        </template>
      </div>
    </div>

    <div v-if="showCTABack && !message.error" class="cta-back">
      <button class="button is-text is-small" @click="goBack">
        <span v-if="hasAllPermissions" key="cta-icon">
          <i class="fas fa-undo-alt" />
        </span>
        <span v-else key="cta-text">Enviar outro tipo?</span>
      </button>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import {
  UnicoCheckBuilder,
  UnicoThemeBuilder,
  DocumentCameraTypes,
  UnicoConfig
} from 'unico-webframe'
import _ from 'lodash'
import * as Sentry from '@sentry/vue'

import themeMixin from '@/mixins/themeMixin'
import navigatorCompatibility from '@/mixins/navigatorCompatibility'

import config from '@/lib/data/config'

import ValidationAPI from '@/lib/api/Validation'

export default {
  name: 'ValidationPhotoDocs',
  components: {
    BaseHeader: defineAsyncComponent(() => import('@/components/BaseHeader')),
    TheError: defineAsyncComponent(() => import('@/components/TheError'))
  },
  mixins: [navigatorCompatibility, themeMixin],
  props: {
    docSide: {
      type: String,
      default: '',
      required: true
    },
    docType: {
      type: String,
      default: '',
      required: true
    }
  },
  data: () => ({
    sdk: {
      config: null,
      builder: null,
      theme: null,
      camera: null,
      models: `${config.UNICO_PUBLIC_FOLDER_PATH}/models`,
      resources: `${config.UNICO_PUBLIC_FOLDER_PATH}/resources`
    },
    form: {
      img: '',
      photos: {
        docs: {
          base64: '',
          encrypted: ''
        }
      }
    },
    config: {
      showInfo: false,
      expanded: true,
      loading: false
    },
    message: {
      error: false,
      text: null
    },
    typesWithTypification: [
      'CNH',
      'CNH_FRENTE',
      'CNH_VERSO',
      'CPF',
      'RG_FRENTE',
      'RG_VERSO',
      'RG_FRENTE_NOVO',
      'RG_VERSO_NOVO'
    ]
  }),
  computed: {
    ...mapState({
      sides: (state) => state.validation.sides,
      process_id: (state) => state.validation.process_id
    }),
    ...mapGetters({
      sidesOptions: 'validation/getSidesOptions'
    }),
    facingMode () {
      return 'environment'
    },
    isVerse () {
      return this.docSide === this.sides.verso
    },
    isOpened () {
      return this.docSide === this.sides.aberto
    },
    docSideLabel () {
      const options = {
        frente: 'Página com FOTO',
        verso: 'Página com CPF'
      }
      const label = this.sidesOptions[this.docSide]

      if (this.docType === 'CTPS') { return options[this.sidesOptions[this.docSide]] }

      return label
    },
    docCameraTypes () {
      const label = this.docSideLabel.toUpperCase()
      const type = this.docType

      if (type === 'CTPS') return 'CTPS'

      if (type === 'NEWRG') return `RG_${label}_NOVO`

      if (this.isOpened) return type

      return `${type}_${label}`
    },
    hasAllPermissions () {
      return this.sdk.camera !== null
    },
    hasPhotoLoaded () {
      return this.form.img !== ''
    },
    showTitle () {
      if (this.message.error) return true
      if (this.hasAllPermissions && this.config.expanded) return false
      return true
    },
    showTutorial () {
      if (this.message.error === true) return true
      return this.config.expanded
    },
    showCTABack () {
      if (this.hasAllPermissions) return !this.config.expanded
      return true
    },
    validationConfig () {
      if (this.isDomainToChange(window.location.hostname)) {
        return {
          u_host_info: process.env.VUE_APP_HOST_INFO,
          u_host_key: process.env.VUE_APP_HOST_KEY,
          u_hostname: process.env.VUE_APP_HOSTNAME,
          u_project_id: process.env.VUE_APP_PROJECT_ID,
          u_project_number: process.env.VUE_APP_PROJECT_NUMBER,
          u_mobile_sdk_app_id: process.env.VUE_APP_MOBILE_SDK_APP_ID
        }
      }

      return this.theme.validation
    }
  },
  watch: {
    $route () {
      this.play()

      this.addAnimation(this.$refs.container)
      this.addAnimation(this.$refs.header.$el)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async (vm) => await vm.routeProtection({ vm, route: { to, from } }))
  },
  async mounted () {
    if (this.docType === '') this.goBack()
  },
  methods: {
    // handlers
    resetImage ({ toPlay = false } = {}) {
      const img = this.$refs.img?.querySelector('img') || null
      this.form.img = ''

      if (img === null) return

      this.removeAnimation(img, 'fade-in')

      if (toPlay) this.play()
    },
    handleError (err) {
      const { docSide: side, docType: type, process_id } = this

      this.message.text = _.isString(err)
        ? err
        : err?.response?.data?.error?.message ||
          err?.error?.response?.data?.error?.message ||
          err?.error?.message ||
          err?.message ||
          'Ocorreu um problema durante sua solicitação'

      if (process.env.VUE_APP_ENV === 'production') {
        Sentry.captureMessage(new Error('photo-docs'), {
          tags: {
            ...this.account,
            type,
            side,
            process_id
          }
        })
      }

      this.message.error = true
      this.config.expanded = true
      this.config.loading = false
    },
    handleSuccess ({ base64, encrypted }, target = 'docs') {
      const base64Formatted = !~base64.indexOf('data:image')
        ? `data:image/jpeg;base64,${base64}`
        : base64

      this.form.photos[target] = {
        base64: base64Formatted,
        encrypted
      }
      this.form.img = base64Formatted
    },
    getFormattedData () {
      const { account_id, name, document, proposal_id } = this.account
      const { base64: img, encrypted } = this.form.photos.docs
      const { docSide: side, docType: type, process_id } = this

      return {
        name,
        document,
        account_id,
        img,
        encrypted,
        type,
        side,
        process_id,
        ...(this.isCorban ? '' : { proposal_id })
      }
    },
    addAnimation (target, animation) {
      // reset animation effect
      try {
        target.style.animation = 'none'
        // eslint-disable-next-line no-unused-expressions
        target.offsetWidth
        target.style.animation = null
      } catch (error) {
        console.log(error)
      }

      // add new animation
      if (animation) target.classList.add(animation)
    },
    removeAnimation (target, animation) {
      if (target) target.classList.remove(animation)
    },
    handleImageLoaded (event) {
      const target = event.target
      const { width, height, style } = target

      if (width > height) {
        style.setProperty('transform', 'rotate(90deg) scale(1.5)')
      } else style.setProperty('transform', 'initial')

      this.addAnimation(target, 'fade-in')
    },

    // api
    async submit () {
      const api = new ValidationAPI()
      const params = this.getFormattedData()

      this.config.loading = true
      this.message.error = false
      this.message.text = null

      try {
        if (this.isCorban) await api.postCorbanDocs(params)
        else await api.postDocs(params)

        this.nextStep()
      } catch (err) {
        this.handleError(err || { error: err })
      } finally {
        this.resetImage()
        this.config.loading = false
      }
    },

    // navigation
    retry () {
      window.location.reload(true)
    },
    goBack () {
      const name = this.isCorban
        ? this.routesNames.TYPE_CORBAN
        : this.routesNames.TYPE

      this.$router.push({ name })
    },
    nextStep () {
      const { params } = this.$route

      // from (A) to (B)
      let type = this.docType
      let side = this.sides.verso
      let mode = this.facingMode
      let terms = false
      let name = this.isCorban
        ? this.routesNames.DOCS_CORBAN
        : this.routesNames.DOCS

      this.config.expanded = false

      // from (B || C) to (Final)
      if (this.isOpened || this.isVerse) {
        type = ''
        side = ''
        mode = ''
        terms = this.account.terms
        name = this.isCorban
          ? this.routesNames.FINAL_CORBAN
          : this.routesNames.FINAL
      }

      this.$router.push({
        name,
        params: {
          ...params,
          terms,
          ...(type ? { type } : ''),
          ...(side ? { side } : ''),
          ...(mode ? { mode } : '')
        }
      })
    },

    // unico
    instantiateNewConfig () {
      const { validationConfig } = this

      try {
        this.sdk.config = new UnicoConfig()
          .setProjectNumber(validationConfig.u_project_number)
          .setProjectId(validationConfig.u_project_id)
          .setMobileSdkAppId(validationConfig.u_mobile_sdk_app_id)
          .setHostname(validationConfig.u_hostname)
          .setHostInfo(validationConfig.u_host_info)
          .setHostKey(validationConfig.u_host_key)
      } catch (err) {
        this.handleError(err)
      }
    },
    instantiateNewBuilder () {
      try {
        this.sdk.builder = new UnicoCheckBuilder()
      } catch (err) {
        this.handleError(err)
      }
    },
    instantiateTheme () {
      try {
        this.sdk.theme = new UnicoThemeBuilder()
          .setColorSilhouetteSuccess('#0384fc')
          .setColorSilhouetteError('#D50000')
          .setColorSilhouetteNeutral('#fcfcfc')
          .setBackgroundColor('#dff1f5')
          .setColorText('#0384fc')
          .setBackgroundColorComponents('#0384fc')
          .setColorTextComponents('#dff1f5')
          .setBackgroundColorButtons('#0384fc')
          .setColorTextButtons('#dff1f5')
          .setBackgroundColorBoxMessage('#fff')
          .setColorTextBoxMessage('#000')
          .setHtmlPopupLoading(
            '<div style="position:absolute;top:45%;right:50%;transform:translate(50%,-50%);z-index:10;text-align:center;">Carregando...</div>'
          )
          .build()
      } catch (err) {
        this.handleError(err)
      }
    },
    async instantiateCamera () {
      try {
        await this.sdk.builder
          .setResourceDirectory(this.sdk.resources)
          .setModelsPath(this.sdk.models)
          .setTheme(this.sdk.theme)

        this.sdk.camera = await this.sdk.builder.build()
      } catch (err) {
        this.handleError(err)
      }
    },
    async loadSDK () {
      this.config.loading = true

      await Promise.all([
        this.instantiateNewConfig(),
        this.instantiateNewBuilder(),
        this.instantiateTheme()
      ])
        .then(async () => {
          await this.instantiateCamera()

          this.config.expanded = false

          await this.play()
        })
        .catch((err) => this.handleError(err))
        .finally(() => {
          this.config.loading = false
        })
    },
    async play () {
      this.resetImage()
      await this.prepareDocs()
    },
    prepareDocs () {
      const _this = this
      const type = this.docCameraTypes
      const documentCameraType = this.typesWithTypification.includes(type)
        ? DocumentCameraTypes[this.docCameraTypes]
        : DocumentCameraTypes.OTHERS(this.docCameraTypes)

      this.config.loading = true

      this.sdk.camera
        .prepareDocumentCamera(this.sdk.config, documentCameraType)
        .then((opener) => {
          this.config.loading = false

          opener.open({
            on: {
              success: (data) => _this.handleSuccess(data, 'docs'),
              error: (err) => _this.handleError(err),
              support: (msg) => console.log(msg)
            }
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';
@import '@/assets/scss/src/_btn.scss';
@import '@/assets/scss/src/_photo.scss';
</style>
