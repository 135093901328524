import config from '../../config'

import PhotoType from '@/views/PhotoType.vue'

const pathSuffix = 'foto-documento'

export default [
  {
    name: `${config.TYPE}`,
    path: `${config.PATH}/:token/${pathSuffix}`,
    component: PhotoType
  },
  {
    name: `${config.TYPE_CORBAN}`,
    path: `${config.ALIAS_PATH}/:token/${pathSuffix}`,
    component: PhotoType
  }
]
