import config from '../../config'

import Consult from '@/views/Consult.vue'

export default [
  {
    name: `${config.CONSULT}`,
    path: `${config.PATH}/:token/confirmar`,
    component: Consult
  }
]
