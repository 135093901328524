<template>
  <div :class="{ loading }" class="validation-login">
    <base-header :has-steps="false" :has-title="false" />

    <div class="validation-login__container fade-in">
      <div v-if="!isLogged" class="validation-login__content">
        <TheWelcome :expanded="expanded" @expand="expanded = $event">
          <template #title>
            Seja bem vindo Corban!
          </template>
          <template #message>
            Este é um sistema simples para validação dos seus documentos, para ativação do seu cadastro.
          </template>
        </TheWelcome>

        <template v-if="message.error">
          <the-error :message="message.text" class="alert mt-1" />
        </template>

        <template v-else>
          <p v-if="isValidDeviceEnv" class="mt-5">Vamos começar?</p>
          <TheInvalid v-else />
        </template>
      </div>

      <template v-if="isValidDeviceEnv && !isLogged">
        <v-validate-observer v-slot="{ handleSubmit }">
          <form
            class="validation-login__form"
            @submit.prevent="handleSubmit(submit)"
          >
            <v-validate
              v-slot="{ errors, field }"
              rules="required|CPF|length:14"
              name="CPF"
            >
              <base-input
                v-bind="field"
                :model-value="form.document"
                :has-error="!!errors[0]"
                :first-error="errors[0]"
                type="tel"
                maxlength="14"
                format="###.###.###-##"
                @input="form.document = field.value"
              >
                <span>CPF</span>
                <span class="required">*</span>
              </base-input>
            </v-validate>

            <v-validate
              v-slot="{ errors, field }"
              :rules="{ regex: regex.date, required: true }"
              name="Data de nascimento"
            >
              <base-input
                v-bind="field"
                :model-value="form.birthday"
                :has-error="!!errors[0]"
                :first-error="errors[0]"
                type="tel"
                maxlength="10"
                format="##/##/####"
                @input="form.birthday = field.value"
              >
                <span>Data de Nascimento</span>
                <span class="required">*</span>
              </base-input>
            </v-validate>

            <div>
              <button :disabled="loading" class="button btn" type="submit">
                <span>Login</span>
                <span class="icon">
                  <i class="fas fa-sign-in-alt" />
                </span>
              </button>
            </div>
          </form>
        </v-validate-observer>
      </template>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import themeMixin from '@/mixins/themeMixin'
import navigatorCompatibility from '@/mixins/navigatorCompatibility'

import ValidationAPI from '@/lib/api/Validation'

export default {
  name: 'ValidationLoginCorban',
  mixins: [themeMixin, navigatorCompatibility],
  components: {
    BaseHeader: defineAsyncComponent(() => import('@/components/BaseHeader')),
    BaseInput: defineAsyncComponent(() => import('@/components/BaseInput')),
    TheError: defineAsyncComponent(() => import('@/components/TheError')),
    TheInvalid: defineAsyncComponent(() => import('@/components/TheInvalid')),
    TheWelcome: defineAsyncComponent(() => import('@/components/TheWelcome'))
  },
  data: () => ({
    form: {
      document: '',
      birthday: ''
    },
    regex: {
      date: /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/
    },
    message: {
      error: false,
      text: null
    },
    expanded: false,
    loading: false,
    isLogged: false,
    fraudStep: 0
  }),
  computed: {
    token () {
      return this.$route.params.token
    },
    logo () {
      return this.theme.logo?.desktop || ''
    }
  },
  methods: {
    // handlers
    async setData ({ ...data }) {
      this.account = data
      this.isLogged = true
    },
    handleError ({ error }) {
      this.message.text =
        error?.response?.data?.error?.message ||
        error?.error?.response?.data?.error?.message ||
        error?.message ||
        error

      if (error?.errors) {
        for (const key in error?.errors) {
          if (Object.hasOwnProperty.call(error?.errors, key)) {
            const [message] = error?.errors[key]
            this.message.text += message
          }
        }
      }

      this.message.error = true
    },
    getFormattedData () {
      return {
        document: this.form.document,
        birthdate: this.form.birthday,
        token: this.token
      }
    },

    // api
    async submit () {
      const api = new ValidationAPI()
      const data = this.getFormattedData()

      this.loading = true
      this.message.error = false
      this.message.text = null

      await api
        .loginCorban(data)
        .then(async ({ data }) => {
          await this.setData(data)

          this.$router.push({ name: `${this.routesNames.SELFIE_CORBAN}` })
        })
        .catch(({ response }) =>
          this.handleError(response?.data || { error: response })
        )
        .finally(() => {
          this.loading = false
        })
    }

    // navigation
    // ...
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/src/_validation.scss';
@import '@/assets/scss/src/_btn.scss';
@import '@/assets/scss/src/_loading-skeleton.scss';

.validation-login {
  @include base();

  @import '@/assets/scss/src/_login.scss';
}
</style>
