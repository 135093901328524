import config from '../../config'

import Login from '@/views/Login.vue'
import LoginCorban from '@/views/LoginCorban.vue'

export default [
  {
    name: `${config.LOGIN}`,
    path: `${config.PATH}/:token?`,
    component: Login
  },
  {
    name: `${config.LOGIN_CORBAN}`,
    path: `${config.ALIAS_PATH}/:token?`,
    component: LoginCorban
  }
]
