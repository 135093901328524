/** external */
import { createRouter, createWebHistory } from 'vue-router'
/** config */
import config from './config'
/** modules */
import Login from './modules/login'
import Basic from './modules/basic'
import Consult from './modules/consult'
import CredCart from './modules/credcart'
import Selfie from './modules/selfie'
import Type from './modules/type'
import Docs from './modules/docs'
import Final from './modules/final'
import INSS from './modules/inss'
import VideoCall from './modules/video-call'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...VideoCall,
    ...Login,
    ...Basic,
    ...Consult,
    ...CredCart,
    ...Selfie,
    ...Type,
    ...Docs,
    ...Final,
    ...INSS,
    {
      path: '/:pathMatch(.*)*',
      redirect: to => {
        const host = to.path.split('/').filter(Boolean)[0] || config.PATH.slice(1)
        const token = (to.params.pathMatch && to.params.pathMatch.length === 1)
          ? to.params.pathMatch[0]
          : 'not-found'

        return {
          path: `/${host}/${token}`
        }
      }
    }
  ]
})

export default router
